import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Emotional Growth Objectives`}</strong>{` include: the ability to: separate from parents; verbalize feelings; begin to develop inner control; take turns and wait; begin to make some choices; show interest/attention in classroom activities; develop a positive self-image; display enthusiasm about accomplishments.`}</p>
    <p><strong parentName="p">{`Social Growth Objectives`}</strong>{` include: enjoying school and being with other children; initiating activity with other children; learning to stay with a group for an activity; beginning to share, take turns, and show courtesy and respect the rights and feelings of others; playing cooperatively; cooperating in classroom cleanup and beginning to develop a helpful attitude toward the group.`}</p>
    <p><strong parentName="p">{`Physical Growth Objectives`}</strong>{` include: improving large and small muscle coordination; developing ability to jump, hop and run; developing good balance; developing hand-eye coordination; dressing with a minimum of help; caring for bathroom needs independently; recognizing and developing all five senses; discriminating likeness/differences in objects; responding to listening games; and developing a sense of directionality (perceiving and orienting oneself to the top/bottom/sides/front/back of objects).;`}</p>
    <p><em parentName="p">{`Intellectual Growth Objectives vary by depending on the age of the child.`}</em></p>
    <p><strong parentName="p">{`Intellectual Growth Objectives`}</strong>{` for three-year-olds include: improving language skills and vocabulary (receptively and expressively); following simple directions; increasing attention span; enjoying learning new things; matching objects by color, shape, and size; developing an inquiring attitude; introducing numbers/shapes/measurement/time; name recognition and sight vocabulary; fostering imagination and differentiating between reality and make-believe; increasing listening skills; beginning to use inner resources instead of relying on adults; and becoming aware of language symbols.`}</p>
    <p><strong parentName="p">{`Intellectual Growth Objectives`}</strong>{` for four- and five-year-olds continue to expand on the growth objectives above, and include: deeper understanding of concepts of shapes, size, measurement and time; awareness of language symbols and familiarity with alphabet; development of sight vocabulary; familiarity with numbers and development of counting skills; continued development of listening skills; speaking clearly enough for adults to understand; speaking in complete sentences and in proper order; participation in conversations with other children; participation in songs and in finger plays; remaining attentive during circle time; using drawing writing tools with control; using scissors with control; showing control with glue, paint, and play dough; picking up and manipulating objects with ease.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      